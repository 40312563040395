import { Flex, Image } from "@chakra-ui/react"
import banner from "../assets/banner.png"
const rektArray=["rektpixels.gif", "rektpixels1.gif"]                        

const ProjectDescription=({dataEth, setDataEth}) => {

    const rekt=rektArray[Math.floor(Math.random() * rektArray.length)];

    return(
        <>
            <Flex justify="center" align="center" height="100%" paddingBottom="0px" paddingTop="0px">
                <Image className="descTitle" src={banner} />
            </Flex>
            <Flex justify="center" align="center" height="100%" paddingBottom="30px" marginTop="-2px">
                <img className="descImage" src={require("../assets/"+rekt)}/>
            </Flex>
        </>
    )
}

export default ProjectDescription;