import React, { Component } from 'react';
import { Flex, Text } from "@chakra-ui/react"

class PageFooter extends Component {
    render() {
        return(
                <Flex backgroundColor="#101213" justify="center" align="center" width="100%" marginTop="30px">
                    <Flex justify="left" align="left" width="50%" paddingLeft="50px" >
                    <Text color="#FAFEFA" >MADE BY A TRULY REKT GUY @COOLNFTART</Text>
                    </Flex>
                    <Flex justify="right" align="right" paddingRight="50px" width="50%">
                        <Text color="#FAFEFA">NO RIGHTS 2023</Text>
                    </Flex>
                </Flex>
        )
    }
}

export default PageFooter;