import { ethers } from "ethers";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

const updateContractInfo = async (contract, walletAddress) => {
    let tokenSymbol="", totalSupply=0, freeMinted=0,
        freeMintPrice=0, maxFreeMintsPerTX=0, freeSupply=0, freeMintCount=0, walletFreeMintsEnabled=true, freeMintEnded=false,
        mintPrice=0, maxMintsPerTX=0, maxSupply=0, pausedMint=false,
        enabledMint=false, loadedContract=false;

    try {
        enabledMint = await contract.saleEnabled();

        let response = await contract.price();
        if (response)  mintPrice=ethers.utils.formatEther(response);

        response = await contract.priceFree();
        if (response) freeMintPrice=ethers.utils.formatEther(response);
        
        maxSupply = ethers.utils.formatUnits(await contract.maxSupply(), 0);
        maxMintsPerTX = parseInt(ethers.utils.formatUnits(await contract.maxMintsPerTX(), 0));
        totalSupply = ethers.utils.formatUnits(await contract.totalSupply(), 0);

        tokenSymbol=await contract.symbol();

        freeSupply = parseInt(ethers.utils.formatUnits(await contract.freeMintSupply(), 0));
        freeMintCount = parseInt(ethers.utils.formatUnits(await contract.freeMintCount(), 0));
        maxFreeMintsPerTX = parseInt(ethers.utils.formatUnits(await contract.maxFreeMintsPerTX(), 0));
        freeMinted=parseInt(ethers.utils.formatUnits(await contract.checkFreeMint(walletAddress), 0));

        walletFreeMintsEnabled=freeMinted<maxFreeMintsPerTX;
        freeMintEnded=freeMintCount===freeSupply;
        
        pausedMint = !enabledMint && totalSupply>0;
        
        loadedContract = true;
    } catch (error) {
        console.error(error)
        let msgDecrypted=getParsedEthersError(error);
        const _errMsg = {title: "Connection Error", description: msgDecrypted.context, status: "error"};
        // setMessage(_errMsg)
    }

    return { tokenSymbol: tokenSymbol, walletFreeMintsEnabled: walletFreeMintsEnabled,
            freeMinted:freeMinted, maxFreeMintsPerTX: maxFreeMintsPerTX, freeMintCount: freeMintCount, freeSupply: freeSupply, freeMintPrice: freeMintPrice, freeMintEnded: freeMintEnded,
            mintPrice: mintPrice, maxMintsPerTX:maxMintsPerTX, maxSupply: maxSupply, totalSupply: totalSupply, 
            enabledMint: enabledMint, pausedMint:pausedMint, loadedContract: loadedContract };
}


export default updateContractInfo;
