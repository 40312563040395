import { useEffect, useState } from 'react';
import { useToast } from "@chakra-ui/react"

export function useToastHook() {
    const [state, setState] = useState(undefined);
    const toast=useToast();

    useEffect(() => {
        if (state) {
            const message = state;

            toast({
                title: message?.title && message.title.slice(0,30),
                description: message?.description && message.description.slice(0,200),
                status: message.status!==undefined? message.status : "info",
                duration: message.duration!==undefined? message.description:9000,
                isClosable: message.isClosable!==undefined? message.isClosable: true
            });
        }
    }, [state, toast]);

    return [state, setState];
}

