import { useEffect } from 'react';
import { Button, } from "@chakra-ui/react"
import { ethers } from "ethers";
import Contract from '../Contract.json'
import env from '../env';
import updateContractInfo from './updateContractInfo';
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

var sha1 = require('sha1');

const MetamaskConnect = ({ dataEth, setDataEth, messsage, setMessage}) => {

    useEffect(() => {
        if(window.ethereum) {
          window.ethereum.on('chainChanged', () => {
            // console.log("net changed")
            checkNetwork();
            // return () => {
            //     window.ethereum.removeListener("chainChanged", networkChanged);
            //   };
          });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                if (localStorage.getItem('onAccountsChangedCooldown')) return;
                localStorage.setItem('onAccountsChangedCooldown', true);
                setTimeout(() => { localStorage.setItem('onAccountsChangedCooldown', false); }, 1000)
                clickConnectButton();
                // return () => {
                //     window.ethereum.removeListener("chainChanged", networkChanged);
                //   };
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkNetwork = async() => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const networkConnected = await provider.getNetwork()
        const networkID = `0x${networkConnected?.chainId.toString(16)}`;

        if (!dataEth?.isConnected||!dataEth?.contractInfo?.loadedContract) {
            await clickConnectButton();
            return;
        }
        
        setDataEth((prevState) => ({
            ...prevState,
            networkConnected: networkID
        }));

    }

    const verifyMessage = async (message, address, signature) => {
        if (!signature) return false;
        address=address.toUpperCase();
        try {
          const signerAddr = await ethers.utils.verifyMessage(message, signature);
          if (signerAddr.toUpperCase() !== address) {
            return false;
          }
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
    }
    
    const updateWalletContractData = async (account) => {
        try {
            // Get Wallet Info
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const balance = ethers.utils.formatEther(await provider.getBalance(account));
            const block = await provider.getBlockNumber();
            const networkConnected = await provider.getNetwork()
            const networkID = `0x${networkConnected?.chainId.toString(16)}`;
    
            //Get Contract Info
            const signer = provider.getSigner();

            const contract = new ethers.Contract(
                env.mintContract,
                Contract.abi,
                signer
            );
            
            const contractInfo=await updateContractInfo(contract, account)
            const data = {
                networkConnected: networkID,
                isConnected: Boolean(account),
                accountEth: { account: account, balance: balance },
                contractInfo: contractInfo,
                block: block
            };

            setDataEth(data);
        } catch (error) {
            console.log(error)
            let msgDecrypted=getParsedEthersError(error);
            const _errMsg = {title: "Connection Error", description: msgDecrypted, status: "error"};
            setMessage(_errMsg)
        }
    
        return;
    
    }
    
    const clickConnectButton = async () => {
        let account=null;
        if (!window.ethereum) {
            console.log("Install Metamask!")
            return;
        }
    
        try {
            // const accounts = await window.ethereum.request({ method: 'wallet_requestPermissions',
            //     params: [{
            //         eth_accounts: {}
            //     }]
            // }).then(() => window.ethereum.request({ method: 'eth_requestAccounts' }))
    
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts'});
            account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum)
    
            const signer = provider.getSigner();
            const message="Please sign in to RektPixels Authentication\r\nid:"+sha1(account);
            const variableName="RektPixelsSignature";
            let authorizedJSON=localStorage.getItem(variableName);
            let previousSign=null
            let authorizedAccts={};

            if (authorizedJSON) {
                try {
                    authorizedAccts=JSON.parse(authorizedJSON);
                }catch{
                    authorizedAccts={};
                }
                if( !(typeof authorizedAccts === "object" || typeof authorizedAccts === 'function') && (authorizedAccts !== null)) {
                    console.log("NO object")
                    authorizedAccts={};
                }
                previousSign=authorizedAccts[account.toUpperCase()];
                // previousSign=authorizedAccts.find(e => e.account==account.toUpperCase());
                // if (previousSign) previousSign=previousSign.signedMessage;
            }
            
            const checkSignature=await verifyMessage(message, account, previousSign);

            if (!checkSignature) {
                const signature = await signer.signMessage(message);
                // authorizedAccts.push({account:account.toUpperCase(), signedMessage:signature});
                authorizedAccts[account.toUpperCase()]=signature;
                authorizedJSON=JSON.stringify(authorizedAccts,null);
                localStorage.setItem(variableName, authorizedJSON);
            }

            //setMessage({title: "Debug", description: "Debug Info", status: "error"});

            return updateWalletContractData(account);
    
        } catch (error) {
            console.log(error)
            let msgDecrypted=getParsedEthersError(error);
            const _errMsg = {title: "Connection Error", description: msgDecrypted, status: "error"};
            setMessage(_errMsg)

        }
    
        return updateWalletContractData(account);
    
    }

    return (
        <Button
                backgroundColor="#121314"
                color="#FAFAFA"
                borderRadius="0px"
                cursor="pointer"
                fontFamily="inherit"
                fontSize="24"
                padding="5px"
                paddingLeft="65px"
                paddingRight="65px"
                marginTop="0px"
                zIndex="100"
                onClick={() => clickConnectButton(true)}
            >CONNECT
        </Button>
    )
}

export default MetamaskConnect;
