import { forwardRef } from 'react';
import {  Text } from "@chakra-ui/react"

const Faq = forwardRef((props, ref) => {
        if (props.dataNav!=="faq") return (<div ref={ref}></div>);
        return(
            <div ref={ref}>
                    <Text marginBottom="10px"  marginLeft="50px" align="left" fontSize="28" fontWeight="600">
                        FAQ
                    </Text>
                    <Text marginBottom="10px" marginTop="10px" marginLeft="50px" align="left" fontSize="22" fontWeight="600">
                        DISTRIBUTION
                    </Text>
                    <Text marginBottom="5px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        4096 REKT PIXELS
                    </Text>
                    <Text marginBottom="5px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        1 FREE PER WALLET & ADDITIONALS AT 0.002 ETH
                    </Text>
                    <Text marginBottom="5px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        COLLECT AS MANY AS YOU WANT
                    </Text>
                    <Text marginLeft="50px" align="left" fontSize="22" fontWeight="600">
                        ROADMAP
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        NOPE, YOU GET WHAT YOU MINT, ANOTHER ADDITION TO THE MEME CULTURE. YOU CAN HAVE A PIECE OF IT IF YOU WANT AND THAT'S IT.
                    </Text>
                    <Text marginLeft="50px" align="left" fontSize="22" fontWeight="600">
                        LICENSING
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        CC0, YOU ARE FREE TO DO WHATEVER YOU WANT WITH YOUR REKTPIXEL
                    </Text>
            </div>
        )
    });

export default Faq;