import React from 'react';
import env from '../env';

import MetamaskShowETH from './ShowETH';
import MetamaskConnect from './Connect';
import MetamaskChangeNetwork from './ChangeNetwork';


const Metamask = ({ dataEth, setDataEth, message, setMessage }) => {

    const showConnectButton = () =>{
        return <MetamaskConnect dataEth={dataEth} setDataEth={setDataEth} message={message} setMessage={setMessage} />
    }

    const showAccountETH = () => {
        return (<MetamaskShowETH dataEth={dataEth} setDataEth={setDataEth} />);
    }

    const showChangeNetwork = () => {
        return (<MetamaskChangeNetwork dataEth={dataEth} setDataEth={setDataEth} message={message} setMessage={setMessage} />);
    }

    const renderMetamask  = () => {
        return (dataEth?.networkConnected!==undefined && dataEth?.networkConnected!==env.networks[env.networkRequired].chainId? 
                    (
                        showChangeNetwork()
                    ) : 
                        (dataEth?.isConnected? (
                            showAccountETH()
                        ) :
                        (   
                            showConnectButton()
                        )
                    )
                );

    }
    
    return(
            <>
                {renderMetamask()}
            </>
    )
}

export default Metamask;