import React from 'react';
import { Button, } from "@chakra-ui/react"
import env from '../env';

const MetamaskChangeNetwork = ({ dataEth, setDataEth, message, setMessage }) => {

    const changeNetwork = async (networkName, setMessage) => {
        try {
            if (!window.ethereum) throw new Error("No crypto wallet found");
            await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: networkName }],
            });
        } catch (err) {
            setMessage({title: "Error", description: err.message, status: "error"});
        }
    }

    const handleNetworkSwitch = async () => {
        setMessage();
        await changeNetwork(env.networks[env.networkRequired].chainId, setMessage);
    };

    return (
        <Button
                backgroundColor="#121314"
                color="#FAFAFA"
                borderRadius="0px"
                cursor="pointer"
                fontFamily="inherit"
                fontSize="16"
                padding="5px"
                paddingLeft="75px"
                paddingRight="75px"
                marginTop="0px"
                zIndex="100"
                
                onClick={() => handleNetworkSwitch()}
            >Change Network
        </Button>
    )
}


export default MetamaskChangeNetwork;
