import { forwardRef } from 'react';
import {  Text } from "@chakra-ui/react"

const Terms = forwardRef((props, ref) => {
        if (props.dataNav!=="terms") return (<div ref={ref}></div>);
        return(
            <div ref={ref}>
                    <Text marginBottom="10px"  marginLeft="50px" align="left" fontSize="28" fontWeight="600">
                        TERMS & CONDITIONS
                    </Text>
                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="22">
                        Greetings All. 
                    </Text>
                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        Please refrain from taking legal action against me. Let's be civil. If you are upset, take a moment to clear your mind, find peace in your heart, and then contact me at rektpixelsnft at gmail dot com. We can work to resolve the issue.
                    </Text>
                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        The term "REKTPIXELS" refers to the REKTPIXELS website, the smart contract, the NFTs, and all related content. However, there is an indefinable aspect that is always present.
                    </Text>
                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        By interacting with REKTPIXELS, you agree to these terms. If any part of this agreement is invalid by law, the remainder of the agreement will remain in effect. The source of both mystery and manifestation is the same, and it is called Darkness.
                    </Text>
                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        REKTPIXELS is provided "as is," without any express or implied warranties.
                    </Text>
                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        The creator shall not be held liable for any claims, damages, or other liability arising out of or in connection with REKTPIXELS or related content, or any use or other dealings with REKTPIXELS. If you act without expectations, you will be free from suffering.
                    </Text>

                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        REKTPIXELS is not a financial product or investment. You should not interact with REKTPIXELS with the intention of investing or making a financial profit. Feel free to use it as you like. I will probably start by launching an ETF IRL merged with a few hundred of REKTPIXELS and start selling some options of it at Robinhood.
                    </Text>

                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        You should not acquire a REKTPIXELS NFT unless you are prepared to sustain a total loss of the cost of acquisition. Be prepared to take a huge gain or a huge loss. Success is just as dangerous as failure.
                    </Text>

                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        Owning a REKTPIXELS token does give the owner commercial rights to the image, and It is appropriate t create commercial ventures themed after your REKTPIXELS tokens, such as a cartoon show, merchandise, or a burger restaurant. 
                    </Text>

                    <Text marginBottom="15px" marginTop="5px" marginLeft="70px" marginRight="70px" align="left" fontSize="18">
                        Please don’t use them for anything bad, just cool stuff.
                    </Text>
            </div>
        )
    });

export default Terms;