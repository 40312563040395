import { forwardRef } from 'react';
import { Image, Flex, Text } from "@chakra-ui/react"
import irl1 from "../assets/IRL1.png"
import irl2 from "../assets/IRL2.png"
import irl3 from "../assets/IRL3.png"

const About = forwardRef((props, ref) => {
        if (props.dataNav!=="about") return (<div ref={ref}></div>);
        return(
            <div ref={ref}>
                    <Text marginLeft="50px" align="left" fontSize="28" fontWeight="600">
                        ABOUT
                    </Text>
                    
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14" >
                        REKT PIXELS IS A COLLECTION INSPIRED BY THE REKTGUY, WITH OVER 100 DIFFERENT TRAITS BASED ON THE PIXEL MEME CULTURE, AND THANKS TO THE POWER OF CC0, YOU'LL FIND REFERENCES TO XCOPY, NOUNS, CRYPTOTOADZ, CRYPTODICKBUTTS AND THE REKTGUY.
                    </Text>
                    <Text paddingTop="20px" marginBottom="10px"  marginLeft="50px" align="left" fontSize="28" fontWeight="600">
                        REKTPIXELS IRL
                    </Text>
                    <Flex justify="center" align="center" width="100%">
                        <Flex justify="center" align="center" width="30%" paddingBottom="30px" marginTop="0px">
                            <Image src={irl2} width="100%" maxWidth="680px" borderRadius='0px' marginRight="60px"/>
                        </Flex>
                        <Flex justify="center" align="center" width="30%" paddingBottom="30px" marginTop="0px">
                            <Image src={irl1} width="100%" maxWidth="680px" borderRadius='0px' marginRight="30px"/>
                        </Flex>
                        <Flex justify="center" align="center" width="30%" paddingBottom="30px" marginTop="0px">
                            <Image src={irl3} width="100%" height="90%" maxWidth="680px" borderRadius='0px' />
                        </Flex>
                    </Flex>
            </div>
                

        )
});

export default About;