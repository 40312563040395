import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, theme } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';


import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ThemeProvider theme={theme}>
        <ChakraProvider>
          <App />
        </ChakraProvider>
    </ThemeProvider>
  </React.StrictMode>
);
