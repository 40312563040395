import React from "react";
import { Box, Flex, Text, Image } from "@chakra-ui/react"
import Metamask from '../Metamask/Metamask';

import twitter from "../assets/twitter.png"
import email from "../assets/email.png"
import opensea from "../assets/opensea.png"
import etherscan from "../assets/etherscan.png"

import env from '../env';

const NavBar = ({ props,
    dataEth, setDataEth, 
    message, setMessage,
    dataNav, setDataNav,
    aboutRef, faqRef, termsRef
    }) => {

    const scrollToSection = (nombre, elementRef) => {
        setDataNav(nombre);
        if (!elementRef) return;
        if (!elementRef.current) return;
        window.scrollTo({
          top: elementRef.current.offsetTop,
          behavior: "smooth",
        });
      };

    return(
        <>
        <Flex backgroundColor="#121314" justify="None" margin="0px" padding="0px" align="center" zIndex="10001" >
            <Flex 
                color="#FAFAFA"
                justify="None"
                align="right"
                width="80%" 
                padding="10px"
                marginLeft="50px"
            >
                
                <div onClick={() => scrollToSection("about",aboutRef)}>
                    <Text margin="0px" color="#FAFEFA">
                        ABOUT
                    </Text>
                </div>

                &nbsp;|&nbsp; 
                <div onClick={() => scrollToSection("faq",faqRef)}>
                        <Text margin="0px" color="#FAFAFA">
                            FAQ
                        </Text>
                </div>
                &nbsp;|&nbsp; 

                <div onClick={() => scrollToSection("terms",termsRef)}>
                    <Text margin="0px" color="#FAFAFA">
                        T&C
                    </Text>
                </div>
            </Flex>
            <Flex 
                justify="right"
                align="right"
                width="20%" 
                padding="10px"
                marginRight="50px"
            >
                <Box marginLeft="10px"  marginRight="10px">
                    <a href={env.openSeaCollection} target="_blank" rel="noreferrer" >
                        <Image src={opensea} width="20px"/>
                    </a>
                </Box>

                <Box marginLeft="10px"  marginRight="10px">
                    <a href={env.networks[env.networkRequired].etherscanAddress+env.mintContract} target="_blank" rel="noreferrer" >
                        <Image src={etherscan} width="20px"/>
                    </a>
                </Box>
                

                <Box paddingTop="2px" marginLeft="10px"  marginRight="10px">
                    <a href={env.twitter_url} target="_blank" rel="noreferrer" >
                        <Image src={twitter} width="20px"/>
                    </a>
                </Box>
                <Box marginLeft="10px"  marginRight="10px">
                    <a href={env.email} target="_blank" rel="noreferrer" >
                        <Image src={email} width="22px" />
                    </a>
                </Box>

            </Flex>
        </Flex>


        <Flex backgroundColor="#FAFEFA" justify="None" margin="0px" padding="0px" align="right" zIndex="10000" >
            <Flex 
                color="#FAFAFA"
                justify="None"
                align="right"
                width="85%" 
                padding="10px"
                marginLeft="50px"
            >
            </Flex>
            <Flex 
                justify="space-around"
                align="right"
                width="15%" 
                padding="10px"
                marginRight="50px"
            >
                <Metamask dataEth={dataEth} setDataEth={setDataEth} message={message} setMessage={setMessage} />
            </Flex>
        </Flex>

        </>



                
            

    )
}

export default NavBar;
